import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import remcalc from 'remcalc';
import moment from 'moment';

import { Text } from '@cloud/mcloud-ui';
import { searchDevice } from '../api/devices';

const StyledContainer = Styled.div`
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.info};
  ${props => props.error && `background-color: ${props.theme.colors.danger}`};
  ${props => props.device && `background-color: ${props.theme.colors.success}`};
  transition: background-color .2s ease-in-out;
`;

const DeviceInfo = Styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InputContainer = Styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
`;

const StyledText = Styled(Text)`
  margin-bottom: 20px;
  color: #96A9E9;
  ${props => props.error && `color: #F4C3BE`};
  ${props => props.device && `color: #AFD2A9`};
`;

const Input = Styled.input`
  font-size: ${remcalc(26)};
  box-sizing: border-box;
  border-radius: 0;
  padding: 10px;
  width: 100%;
  max-width: 700px;
  height: 70px;
  border-color: #3E5BB9;
  ${props => props.device && `border-color: #32A266`};
  ${props => props.error && `border-color: #D40027`};
`;

const Home = () => {
  const [value, setValue] = useState('');
  const [pending, setPending] = useState(false);
  const [valid, setValid] = useState(false);
  const [device, setDevice] = useState(null);
  const [error, setError] = useState(null);

  const onChange = e => {
    setValue(e.target.value);
  };

  const validateDevice = device => {
    if (device.records.length === 0) {
      setError('No device records found');
      return false;
    }

    if (!device.records[0].metadata) {
      setError('No device metadata found');
      return false;
    }

    if (!device.records[0].metadata.hd271) {
      setError('No data for HD271 board');
      return false;
    }

    if (!device.records[0].metadata.hd272) {
      setError('No data for HD272 board');
      return false;
    }

    if (!device.records[0].metadata.hd271.hw_baseboard) {
      setError('No hw_baseboard info found for HD271 board');
      return false;
    } else {
      if (device.records[0].metadata.hd271.hw_baseboard.indexOf('0x') === -1) {
        setError('Invalid format for HD271 hw_baseboard');
        return false;
      }
    }

    if (!device.records[0].metadata.hd271.device_uuid) {
      setError('No device_uuid info found for HD271 board');
      return false;
    }

    if (!device.records[0].metadata.hd272.device_uuid) {
      setError('No device_uuid info found for HD272 board');
      return false;
    }

    return true;
  };

  useEffect(() => {
    setError(null);
    setDevice(null);
    if (value) {
      setPending(true);
      const handler = setTimeout(async () => {
        setPending(false);
        try {
          const result = await searchDevice(value);
          const valid = validateDevice(result.data);
          setValid(valid);
          return setDevice(result.data);
        } catch (e) {
          setValid(false);
          return setError('No device found for that serial number');
        }
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);
  console.log(device);
  return (
    <StyledContainer
      error={!valid && !!error}
      device={valid && !!device}
      pending={pending}
    >
      <InputContainer>
        <StyledText error={!!error} device={!!device} as="h1" size={30}>
          Serial Number Checker
        </StyledText>
        <Input
          error={!!error}
          device={!!device}
          onChange={onChange}
          value={value}
          placeholder="Start typing a serial number to search..."
        />
        {pending && (
          <Text as="small" align right size="18">
            Searching...
          </Text>
        )}
        {error && !valid && (
          <Text as="small" align right size="18">
            {error}.
          </Text>
        )}
        {device && (
          <DeviceInfo>
            <Text as="p" size={20}>
              <strong>Manufacture Date</strong>:{' '}
              {moment(device.createdAt).format('DD/MM/YYYY hh:mm')}
            </Text>
            <Text as="p" size={20}>
              <strong>Last Updated</strong>:{' '}
              {moment(device.updatedAt).format('DD/MM/YYYY hh:mm')}
            </Text>
          </DeviceInfo>
        )}
        {device && valid && (
          <DeviceInfo>
            {/* <Text
              as='strong'
              size={20}
            >{`Device found`}</Text> */}
            <Text as="p" size={20}>
              <strong>hw_baseboard</strong>:{' '}
              {device.records[0].metadata.hd271.hw_baseboard}
            </Text>
          </DeviceInfo>
        )}
      </InputContainer>
    </StyledContainer>
  );
};

export default Home;
