import axios from 'axios';
import cookies from 'js-cookie';

export const searchDevice = async serialNumber => {
  try {
    const res = await axios.get(
      `api/v2/devices/${serialNumber}?expand=metadata`,
      {
        headers: {
          Authorization: `Bearer ${cookies.get('CLIENT_TOKEN')}`
        }
      }
    );
    return res;
  } catch (e) {
    throw e;
  }
};
