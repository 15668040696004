import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import theme from './theme';
import ThemeModeContext from './context/themeContext';
import useThemeMode from './hooks/useThemeMode';
import GlobalStyle from './globalStyle'

import Home from './pages/Home';

function App() {
  const [themeMode, toggleTheme] = useThemeMode();

  return (
    <div className="App">
      <ThemeProvider theme={theme['LIGHT']}>
        <ThemeModeContext.Provider value={[themeMode, toggleTheme]}>
          <Router>
            <Route exact path="/" component={Home} />
          </Router>
          <GlobalStyle />
        </ThemeModeContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
