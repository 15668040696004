import { createGlobalStyle } from 'styled-components'
import remcalc from 'remcalc'

export default createGlobalStyle`
  html {
    font-size: 100%; /* set base font-size for remcalc */
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  body {
    background: ${props => props.theme.primary};
    color: ${props => props.theme.secondary};
    padding: 0;
    margin: 0;
    input {
      height: 35px;
      margin: 5px 0;
      font-size: 15px;
      border: 1px solid ${props => props.theme.grey};
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
    textarea {
      border: 1px solid ${props => props.theme.grey};
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      width: 100%;
      height: 200px;
    }
    select {
      border: 1px solid ${props => props.theme.grey};
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 10px;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  html, body, #app {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.primary};
    overflow-x: hidden;
  }

  body {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    min-width: 320px;
    background-color: ${props => props.theme.primary};
    font-size: 14px;
    line-height: 1.4285em;
    color: ${props => props.theme.secondary};
  }

  input, textarea {
    color: ${props => props.theme.secondary} !important;
  }

  div {
    box-sizing: border-box;
  }

  h1, h2, h3, h4 {
    letter-spacing: 0.4px;
    margin: 0;
  }

  h1, h2, h3 {
    font-size: ${remcalc(20)};
  }

  h2 {
    font-weight: 700;
    color: ${props => props.theme.secondary};
  }
  
  h3 {
    font-weight: 600;
    color: ${props => props.theme.secondary};
  }

  h4, h5, h6 {
    font-size: ${remcalc(16)};
  }

  h4 {
    font-weight: 400;
    color: ${props => props.theme.secondary};
  }

  h5, h6 {
    font-weight: 300;
    color: ${props => props.theme.secondary};
  }

`
