export default {
  LIGHT: {
    colors: {
      primary: '#fff',
      secondary: '#272822',
      tertiary: '#6d45ff',
      grey: '#f1f1f1',
      red: '#ff4949',
      shadow: 'rgba(0, 0, 0, 0.06) 0px 6px 20px',
      danger: '#96281b',
      warning: '#f15a22',
      info: '#1f3a93',
      success: '#1e824c',
    },
  },
  DARK: {
    colors: {
      primary: '#272822',
      secondary: '#f1f1f1',
      tertiary: '#ff9e9e',
      grey: '#404040',
      red: '#ff4949',
      shadow: 'rgba(255, 255, 255, 0.06) 0px 6px 20px',
      danger: '#ec644b',
      warning: '#f39c12',
      info: '#19b5fe',
      success: '#7befb2',
    },
  },
};
