import { useState } from 'react';
import cookies from 'js-cookie';

export default () => {
  const getThemeMode = () => {
    let mode = cookies.get('mode_academy-builder');

    // set default to LIGHT
    if (!mode) {
      cookies.set('mode_academy-builder', 'LIGHT');
      mode = 'LIGHT';
    }

    return mode;
  };

  const [themeMode, setThemeMode] = useState(getThemeMode);

  const toggleTheme = () => {
    const newThemeMode = themeMode === 'LIGHT' ? 'DARK' : 'LIGHT';

    cookies.set('mode_academy-builder', newThemeMode);
    setThemeMode(newThemeMode);
  };

  return [themeMode, toggleTheme];
};
